export const MENU_HEIGHT = 54;

export const Pages = {
	Home: "/",
	Awards: "/awards",
	Team: "/team",
	TeamBio: "/team/:name",
	News: "/news",
	Contact: "/contact",
	FeedbackBlushbar: "/feedback-blush-bar",
	FeedbackSephoraNA: "/feedback-sephora-na",
	FeedbackSephoraSEA: "/feedback-sephora-sea",
	FeedbackSephoraEMEA: "/feedback-sephora-emea",
	FeedbackSuperpharm: "/feedback-superpharm",
	FeedbackWBA: "/feedback-wba",
};

export const Breakpoints = {
	XS: "xs",
	SM: "sm",
	MD: "md",
	LG: "lg",
	XL: "xl",
};

export const H_Breakpoints = {
	XS: "660px",
	SM: "820px",
	MD: "920px",
	LG: "1060px",
	XL: "1220px",
};
