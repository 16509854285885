import React from "react";
import _ from "lodash";
import { Typography, Box, Divider, Grid } from "@mui/material";
import { colors } from "styles/StyleGuide";
import FullBox from "components/FullBox";
import { styled } from "@mui/system";
import useBreakpoints from "hooks/useBreakpoints";

import imgScannerRed from "img/DEVICE side.png";
import imgScannerSilicone from "img/DEVICE silicon.png";

export default function ScannerInfo({ ...props }) {
	const [breakpoint, isMobile] = useBreakpoints();

	const TitleText = styled(Typography)(({ theme }) => ({
		textAlign: "left",
		font: "normal normal normal 60px/60px Univers",
		[theme.breakpoints.down("sm")]: {
			fontSize: "50px",
		},
		letterSpacing: "-3.6px",
		color: colors.darkBorder,
		opacity: 1,
	}));

	const InfoText = styled(Typography)((props) => ({
		textAlign: "left",
		font: "normal normal normal 32px Univers",
		marginTop: "16px",

		[props.theme.breakpoints.up("xl")]: {
			font: "normal normal normal 45px/50px Univers",
			marginTop: "34px",
		},
		letterSpacing: "0px",
		color: colors.darkBorder,
		opacity: 1,
	}));

	const OutlineText = styled(Typography)({
		textAlign: "center",
		font: "normal normal 600 22px/30px Helvetica Neue",
		letterSpacing: "0px",
		color: colors.darkBorder,
		opacity: 1,

		border: `4px solid ${colors.darkBorder}`,
		borderRadius: "11px",
		width: "150px",
	});

	const SmallText = styled(Typography)({
		textAlign: "left",
		font: "normal normal 600 16px/26px Helvetica Neue",
		letterSpacing: "0px",
		color: colors.darkBorder,
		opacity: 1,
	});

	return (
		<Grid container {...props}>
			<Grid item xs={12} lg={4}>
				<img
					alt="red scanner"
					src={imgScannerSilicone}
					style={{
						width: "100%",
						maxWidth: isMobile ? 285 : 330,
						maxHeight: isMobile ? 457 : 530,
						objectFit: "contain",
						paddingBottom: isMobile ? "70px" : "0px",
					}}
				/>
			</Grid>

			<Grid item container xs={12} lg={8}>
				<Grid
					item
					xs={12}
					sx={{ paddingTop: { xs: "0px", lg: "32px" } }}
				>
					<TitleText>
						The only dermatologist-grade solution for any smartphone
						or tablet!
					</TitleText>
				</Grid>

				<Grid container item xs={12}>
					<Grid container item xs={12} sx={{ marginTop: "58px" }}>
						<Grid item xs={6} sx={{ paddingRight: "4%" }}>
							<OutlineText
								sx={{ paddingY: "8px", paddingX: "26px" }}
							>
								For Skin
							</OutlineText>
							<SmallText sx={{ marginTop: "20px" }}>
								Moisture, Lines, Pores, Oil, Dark Spots,
								Sensitivity, Elasticity,
								Radiance, Skin Tone Evenness/Pigmentation, and Color
							</SmallText>
						</Grid>
						<Grid item xs={6}>
							<OutlineText
								sx={{ paddingY: "8px", paddingX: "26px" }}
							>
								For Hair
							</OutlineText>

							<SmallText sx={{ marginTop: "20px" }}>
								Split Ends, Scalp, Health, Shine, Color
							</SmallText>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	);
}
