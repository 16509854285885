import { createTheme } from "@mui/material/styles";
import { colors } from "styles/StyleGuide";

const theme = createTheme();

export const FitskinTheme = createTheme(theme, {
	palette: {
		primary: {
			light: colors.white,
			main: colors.pink,
			dark: colors.darkBrown,
		},
		button: {
			main: colors.sand,
			contrastText: colors.darkBrown,
		},
		buttonLight: {
			main: colors.pink,
			contrastText: colors.white,
		},
	},

	typography: {
		fontFamily: "Univers, Helvetica Neue, Helvetica",
		h1: {
			textAlign: "left",
			fontStyle: "normal",
			fontVariant: "normal",
			fontWeight: "normal",

			fontSize: "80px",
			fontFamily: "Univers",
			letterSpacing: "-4.8px",
			color: colors.white,
			opacity: 1,
			[theme.breakpoints.down("lg")]: {
				fontSize: "60px",
			},
		},
		body: {
			textAlign: "left",
			font: "normal normal normal 15px/24px Univers",
			letterSpacing: "-0.9px",
			color: colors.white,
			opacity: 1,
		},
	},
	components: {
		MuiLink: {
			styleOverrides: {
				root: {
					"&:hover": {
						opacity: 0.7,
					},
				},
			},
		},

		MuiTextField: {
			styleOverrides: {
				rooty: {
					background: `${colors.pink} 0% 0% no-repeat padding-box`,
					border: `1px solid ${colors.white}`,
					borderRadius: "10px",
					opacity: 1,
				},
			},
		},
	},
});
