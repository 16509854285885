import React from "react";
import MenuBar from "components/MenuBar";
import SliderCarousel from "components/SliderCarousel";
import { Link as RouterLink } from "react-router-dom";
import { Box, Typography, Link } from "@mui/material";
import { Pages } from "Constants";
import useBreakpoints from "hooks/useBreakpoints";

import logoBlack from "img/FitSkin-Logo.svg";
import logoWhite from "img/FitSkin-Logo-White.svg";

type Props = {
	bgImage: string,
	useBlackLogo: boolean,
	autoplay: boolean,
	children: any,
};

export default function ScrollLayout(props: Props) {
	const [breakpoint, isMobile] = useBreakpoints();

	return (
		<Box
			sx={{
				//width: isMobile ? "100vw" : "100vw",
				height: isMobile ? "100%" : "100vh",
				//minWidth: "100vw",
				minHeight: "100vh",
				...(props.bgImage && {
					background: `url('${props.bgImage}') no-repeat`,
					backgroundSize: "cover",
					backgroundAttachment: "fixed",
					backgroundPosition: "center",
				}),
				...(!props.bgImage && { backgroundColor: "white" }),
				overflowX: "hidden",
			}}
		>
			<SliderCarousel bgImage={props.bgImage} autoplay={props.autoplay}>
				{props.children}
			</SliderCarousel>

			<Link
				underline="none"
				component={RouterLink}
				to={Pages.Home}
				sx={{
					position: "fixed",
					right: 28,
					top: 20,
					zIndex: 2,
				}}
			>
				<img
					src={props.useBlackLogo ? logoBlack : logoWhite}
					alt={"fitskin logo"}
					style={{
						width: 140,
						height: 60,
						filter: "drop-shadow(1px 1px 1px rgb(0 0 0 / 0.4))",
					}}
				/>
			</Link>
			<MenuBar useBlackImage={props.useBlackLogo} />
		</Box>
	);
}
