import React, { Suspense, lazy } from "react";
import _ from "lodash";
import { Routes, Route, Link } from "react-router-dom";
import { Pages } from "Constants";
import { Page } from "components/PageLayout";

import Home from "./pages/Home";
import NotFound from "./pages/NotFound";

const Awards = lazy(() => import("./pages/Awards"));
const Team = lazy(() => import("./pages/Team"));
const TeamBio = lazy(() => import("./pages/TeamBio"));
const News = lazy(() => import("./pages/News"));
const Contact = lazy(() => import("./pages/Contact"));

const FeedbackBlushbar = lazy(() => import("./pages/FeedbackBlushbar"));
const FeedbackSephoraNA = lazy(() => import("./pages/FeedbackSephoraNA"));
const FeedbackSephoraSEA = lazy(() => import("./pages/FeedbackSephoraSEA"));
const FeedbackSephoraEMEA = lazy(() => import("./pages/FeedbackSephoraEMEA"));
const FeedbackSuperpharm = lazy(() => import("./pages/FeedbackSuperpharm"));
const FeedbackWBA = lazy(() => import("./pages/FeedbackWBA"));

const PageList = [
	{
		path: Pages.Home,
		element: <Page component={Home} lazy={false} />,
		index: true,
	},
	{
		path: Pages.Awards,
		element: <Page component={Awards} title={"Awards & Press"} />,
	},
	{
		path: Pages.TeamBio,
		element: <Page component={TeamBio} title={"Our Team"} />,
	},
	{ path: Pages.Team, element: <Page component={Team} title={"Our Team"} /> },
	{ path: Pages.News, element: <Page component={News} title={"News"} /> },
	{
		path: Pages.Contact,
		element: <Page component={Contact} title={"Contact"} />,
	},
	{
		path: Pages.FeedbackBlushbar,
		element: (
			<Page
				component={FeedbackBlushbar}
				title="Blush-Bar Feedback Form"
				noIndex
			/>
		),
	},
	{
		path: Pages.FeedbackSephoraNA,
		element: (
			<Page
				component={FeedbackSephoraNA}
				title=" Sephora NA Feedback Form"
				noIndex
			/>
		),
	},
	{
		path: Pages.FeedbackSephoraSEA,
		element: (
			<Page
				component={FeedbackSephoraSEA}
				title="Sephora SEA Feedback Form"
				noIndex
			/>
		),
	},
	{
		path: Pages.FeedbackSephoraEMEA,
		element: (
			<Page
				component={FeedbackSephoraEMEA}
				title="Sephora EMEA Feedback Form"
				noIndex
			/>
		),
	},
	{
		path: Pages.FeedbackSuperpharm,
		element: (
			<Page
				component={FeedbackSuperpharm}
				title="Super-Pharm Feedback Form"
				noIndex
			/>
		),
	},
	{
		path: Pages.FeedbackWBA,
		element: (
			<Page component={FeedbackWBA} title={"WBA Feedback Form"} noIndex />
		),
	},
	{
		path: "*",
		element: <Page component={NotFound} title={"Oops"} lazy={false} />,
	},
];

export default function Navigation() {
	return (
		<Routes>
			{_.map(PageList, (page, index) => (
				<Route
					key={index}
					path={page.path}
					element={page.element}
					index={page.index}
				/>
			))}
		</Routes>
	);
}
