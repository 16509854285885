import React, { Component } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel as ReactCarousel } from "react-responsive-carousel";
import { Button } from "@mui/material";
import { styled } from "@mui/system";
import { colors } from "styles/StyleGuide";

export default function Carousel(props) {
	const Arrow = styled(Button)({
		// Font
		fontStyle: "normal",
		fontVariant: "normal",
		fontWeight: 65,
		fontSize: "32px",
		fontFamily: "Univers",
		letterSpacing: "-4.8px",
		color: colors.darkBorder,
		opacity: 1,

		// position
		position: "absolute",
		zIndex: 2,
		width: 15,
		cursor: "pointer",
		top: 0,
		bottom: 0,
	});

	const ArrowLeft = (onClick, hasPrev, label) => {
		return (
			hasPrev && (
				<Arrow onClick={onClick} sx={{ left: -65 }} color={"inherit"}>
					{"<"}
				</Arrow>
			)
		);
	};

	const ArrowRight = (onClick, hasNext, label) => {
		return (
			hasNext && (
				<Arrow onClick={onClick} sx={{ right: -65 }} color={"inherit"}>
					{">"}
				</Arrow>
			)
		);
	};

	return (
		<ReactCarousel
			showArrows
			swipeable
			emulateTouch
			showStatus={false}
			showThumbs={false}
			renderArrowPrev={ArrowLeft}
			renderArrowNext={ArrowRight}
			swipeScrollTolerance={50}
		>
			{props.children}
		</ReactCarousel>
	);
}
