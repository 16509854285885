import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import { MENU_HEIGHT } from "Constants";
import useBreakpoints from "hooks/useBreakpoints";

export default function FullBox(props) {
	const [breakpoint, isMobile] = useBreakpoints();

	return (
		<Box
			sx={{
				...(!props.fullScreen && !isMobile && { paddingX: "80px" }),
				...(isMobile && { paddingX: "32px" }),
				...(!props.fullScreen &&
					!props.fullHeight && { paddingTop: "187px" }),
				...(isMobile && { paddingTop: "80px" }),
				...(!props.fullScreen && {
					paddingY: `${MENU_HEIGHT + 50}px`,
				}),
				width: "100vw",
				height: isMobile ? "100%" : "100vh",
				boxSizing: "border-box",
				...props.sx,
			}}
		>
			<Box
				sx={{
					width: "100%",
					height: "100%",
					position: "relative",
					display: "flex",
					flexDirection: "column",
					...props.innerSx,
				}}
			>
				{React.Children.map(props.children, (child) =>
					React.cloneElement(child, {
						activeSlide: props.activeSlide,
					})
				)}
			</Box>
		</Box>
	);
}
