import React, { useState, useEffect } from "react";

import scanner01 from "img/scanner/SCANNER 01.png";
import scanner02 from "img/scanner/SCANNER 02.png";
import scanner03 from "img/scanner/SCANNER 03.png";
import scanner04 from "img/scanner/SCANNER 04.png";
import scanner05 from "img/scanner/SCANNER 05.png";
import scanner06 from "img/scanner/SCANNER 06.png";
import scanner07 from "img/scanner/SCANNER 07.png";
import scanner08 from "img/scanner/SCANNER 08.png";

const scanners = [
	scanner08,
	scanner07,
	scanner06,
	scanner05,
	scanner04,
	scanner03,
	scanner02,
	scanner01,
];

function preloadImage(src: string) {
	return new Promise((resolve, reject) => {
		const img = new Image();
		img.onload = function () {
			resolve(img);
		};
		img.onerror = img.onabort = function () {
			reject(src);
		};
		img.src = src;
	});
}

export default function useScannerImages() {
	const [imagesLoaded, setImagesPreloaded] = useState(false);

	useEffect(() => {
		let isCancelled = false;

		async function effect() {
			// console.log("PRELOAD");

			if (isCancelled) {
				return;
			}

			const imagesPromiseList: Promise<any>[] = [];
			for (const i of scanners) {
				imagesPromiseList.push(preloadImage(i));
			}

			await Promise.all(imagesPromiseList);

			if (isCancelled) {
				return;
			}

			setImagesPreloaded(true);
		}

		effect();

		return () => {
			isCancelled = true;
		};
	}, [scanners]);

	return [scanners, imagesLoaded];
}
