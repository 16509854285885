import React from "react";

import ScrollLayout from "../components/ScrollLayout";
import FullBox from "components/FullBox";
import { Typography, Box, Button, Divider } from "@mui/material";
import { colors } from "styles/StyleGuide";
import { Link as RouterLink } from "react-router-dom";

import { Pages } from "Constants";

import imgBg from "img/Background - Contact.jpg";

export default function NotFound() {
	return (
		<ScrollLayout bgImage={imgBg}>
			<FullBox
				innerSx={{
					justifyContent: "center",
					alignItems: "center",
					alignContent: "center",
					//width: "30%",
				}}
			>
				<Box
					sx={{
						display: "flex",
						flexDirection: "column",
						//width: "30%",
						justifyContent: "center",
						alignItems: "center",
						alignContent: "center",
					}}
				>
					<Typography
						sx={{
							fontSize: "225px",
							fontFamily: "Univers",
							fontWeight: 75,
							letterSpacing: 0,
							color: colors.white,
							opacity: 1,
							lineHeight: "225px",
						}}
					>
						404
					</Typography>
					<Typography
						sx={{
							fontFamily: "Univers",
							fontSize: "65px",
							fontWeight: 65,
							letterSpacing: "-3.9px",
							color: colors.white,
						}}
					>
						Page Not Found
					</Typography>
					<Button
						component={RouterLink}
						variant="contained"
						color="buttonLight"
						size="large"
						sx={{
							marginTop: "100px",
							boxShadow: 0,
							border: `1px solid ${colors.white}`,
							borderRadius: "10px",
							width: "100%",
							height: "80px",
						}}
						to={Pages.Home}
						//onClick={onSubmit}
					>
						Back to Home
					</Button>
				</Box>
			</FullBox>
		</ScrollLayout>
	);
}
