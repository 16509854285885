import React, { useState, useEffect } from "react";
import _ from "lodash";
import ScrollLayout from "../components/ScrollLayout";
import FullBox from "components/FullBox";
import { Typography, Box, Button, Divider, Grid } from "@mui/material";
import { colors } from "styles/StyleGuide";
import Carousel from "components/Carousel";
import { styled } from "@mui/system";
import useBreakpoints from "hooks/useBreakpoints";
import LoadingPage from "pages/LoadingPage";
import Scanner3D from "components/Scanner3D";

import useScannerImages from "./Home/useScannerImages";

import WelcomeAnimation from "./Home/WelcomeAnimation";
import ScannerInfo from "./Home/ScannerInfo";
import ScannerAdvantages from "./Home/ScannerAdvantages";
import Clients from "./Home/Clients";

import imgBg from "img/Background - Home.jpg";
import scannerImg from "img/DEVICE on phone.png";

export default function Home() {
	const [breakpoint, isMobile] = useBreakpoints();
	const [scanners, imagesLoaded] = useScannerImages();

	const MobileLayout = () => (
		<ScrollLayout bgImage={imgBg} autoplay={true}>
			<FullBox>
				<WelcomeAnimation
					style={{ paddingBottom: "220px" }}
					scanners={scanners}
				/>
				<ScannerInfo style={{ paddingBottom: "220px" }} />
				<ScannerAdvantages style={{ paddingBottom: "220px" }} />
				<Clients />
			</FullBox>
		</ScrollLayout>
	);

	const DektopLayout = () => (
		<ScrollLayout bgImage={imgBg} autoplay={true}>
			<FullBox
				fullHeight
				innerSx={{
					justifyContent: "center",
					alignItems: "center",
				}}
			>
				<WelcomeAnimation scanners={scanners} />
			</FullBox>
			<FullBox
				fullHeight={!isMobile}
				innerSx={{
					justifyContent: "center",
					alignItems: "center",
					flexDirection: "row",
				}}
			>
				<ScannerInfo />
			</FullBox>
			<FullBox
				fullHeight
				innerSx={{
					justifyContent: "center",
					alignItems: "center",
				}}
			>
				<ScannerAdvantages />
			</FullBox>
			<FullBox>
				<Clients sx={{ height: "100%" }} page={1} />
			</FullBox>
			<FullBox>
				<Clients sx={{ height: "100%" }} page={2} />
			</FullBox>
		</ScrollLayout>
	);

	return imagesLoaded ? (
		isMobile ? (
			<MobileLayout />
		) : (
			<DektopLayout />
		)
	) : (
		<LoadingPage />
	);
}
