import React, { Suspense } from "react";
import _ from "lodash";
import { Helmet } from "react-helmet";
import LoadingPage from "pages/LoadingPage";

const PageSetup = ({ ...props }) => (
	<>
		<Helmet
			titleTemplate="%s - FitSkin®"
			defaultTitle={"FitSkin® - The future of skincare is here"}
		>
			{!!props.title && <title>{props.title}</title>}
			{!!props.description && (
				<meta name="description" content={props.description} />
			)}
			{!!props.noIndex && (
				<meta name="robots" content="noindex,nofollow" />
			)}
			{!!props.metaTags &&
				_.map(props.metaTags, (tag) => (
					<meta
						name={tag.name}
						property={tag.property}
						content={tag.content}
					/>
				))}
		</Helmet>
		{props.component && <props.component {...props} />}
	</>
);

export const Page = (props) =>
	props.lazy === false ? (
		<PageSetup {...props} />
	) : (
		<Suspense fallback={<LoadingPage />}>
			<PageSetup {...props} />
		</Suspense>
	);
