export const colors = {
	pink: "#DFACA9",
	pinkShade1: "#F6E4DF",
	pinkShade2: "#F4DDD7",
	sand: "#DCB88E",
	lightBrown: "#B39378",
	brown: "#976F52",
	darkBrown: "#684938",
	white: "#FFFFFF",
	white_shade_1: "#F7F7F7",
	darkBorder: "#371E03",
	black: "#0F0F0F",
	black_shade_1: "#272727",
	kelvin7000: "#F3F2FF",
	kelvin6000: "#FFF6ED",
	kelvin5500: "#FFEDDE",
	kelvin5000: "#FFE4CE",
	kelvin3000: "#FFB16E",
	orange: "#FF6700",
};
