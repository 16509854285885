import React, { useState, useRef } from "react";
import _ from "lodash";

import { Button } from "@mui/material";
import { styled } from "@mui/system";
import { colors } from "styles/StyleGuide";

// Core modules imports are same as usual
import {
	Navigation,
	Pagination,
	Mousewheel,
	Autoplay,
	EffectFade,
} from "swiper";
// Direct React component imports
import { Swiper, SwiperSlide } from "swiper/react/swiper-react.js";

// Styles must use direct files imports
import "swiper/swiper.scss"; // core Swiper
import "swiper/modules/navigation/navigation.scss";
import "swiper/modules/pagination/pagination.scss";
import "swiper/modules/mousewheel/mousewheel.scss";
import "swiper/modules/autoplay/autoplay.scss";
import "swiper/modules/effect-fade/effect-fade.scss";

export default function SliderCarousel(props) {
	const [activeSlide, setActiveSlide] = useState(1);
	const calcActiveSlide = (index, count) => ((index % count) + count) % count;

	const swiperRef = useRef(null);
	const prevRef = useRef(null);
	const nextRef = useRef(null);

	const Arrow = styled(Button)({
		// Font
		fontStyle: "normal",
		fontVariant: "normal",
		fontWeight: 65,
		fontSize: "32px",
		fontFamily: "Univers",
		letterSpacing: "-4.8px",
		color: colors.darkBorder,
		opacity: 1,

		// position
		position: "absolute",
		zIndex: 2,
		width: 15,
		cursor: "pointer",
		top: 0,
		bottom: 0,
	});

	const ArrowLeft = ({ ref }) => {
		return (
			<Arrow
				ref={ref}
				onClick={() => swiperRef.current.swiper.slidePrev()}
				sx={{ left: 0 }}
				color={"inherit"}
			>
				{"<"}
			</Arrow>
		);
	};

	const ArrowRight = ({ ref }) => {
		return (
			<Arrow
				ref={ref}
				sx={{ right: 0 }}
				color={"inherit"}
				onClick={() => swiperRef.current.swiper.slideNext()}
			>
				{">"}
			</Arrow>
		);
	};

	return props.children.constructor === Array ? (
		<>
			<Swiper
				ref={swiperRef}
				modules={[
					Mousewheel,
					Autoplay,
					EffectFade,
					Pagination,
					Navigation,
				]}
				pagination={{
					bulletActiveClass: "swiper-dot-active",
					bulletClass: "swiper-dot",
					clickable: true,
				}}
				navigation={{
					prevEl: prevRef.current, // Assert non-null
					nextEl: nextRef.current, // Assert non-null
				}}
				onInit={(swiper) => {
					swiper.params.navigation.prevEl = prevRef.current;
					swiper.params.navigation.nextEl = nextRef.current;
					swiper.navigation.update();
				}}
				//mousewheel={true}
				simulateTouch={false}
				slidesPerView={1}
				loop
				speed={500}
				effect={"fade"}
				fadeEffect={{ crossFade: true }}
				autoplay={
					!!props.autoplay
						? {
								delay: 3000,
								pauseOnMouseEnter: true,
								disableOnInteraction: true,
						  }
						: false
				}
				onSlideChange={(s) => {
					setActiveSlide(s.activeIndex);
				}}
			>
				{React.Children.map(props.children, (child, index) => (
					<SwiperSlide
						key={index}
						style={{
							width: "100vw",
							height: "100vh",
						}}
					>
						{React.cloneElement(child, {
							activeSlide: calcActiveSlide(
								activeSlide - 1,
								props.children.length
							),
						})}
						{/* {child} */}
					</SwiperSlide>
				))}
				<ArrowLeft ref={prevRef} />
				<ArrowRight ref={nextRef} />
			</Swiper>
		</>
	) : (
		props.children
	);
}
