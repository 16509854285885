import React, { useRef, useState, Suspense, lazy, useEffect } from "react";
import _ from "lodash";
import { Typography, Box, Button, Divider, Grid } from "@mui/material";
import { styled } from "@mui/system";
import { colors } from "styles/StyleGuide";
import useBreakpoints from "hooks/useBreakpoints";
import { useInView } from "react-intersection-observer";
import R from "components/R";

import Scanner3D from "components/Scanner3D";

const PAGE_INDEX = 2;

export default function ScannerAdvantages({ activeSlide, ...props }) {
	const [breakpoint, isMobile] = useBreakpoints();

	const [showModel, setShowModel] = useState(false);

	const { ref, inView, entry } = useInView({ threshold: 0 });

	useEffect(() => {
		setShowModel(activeSlide === PAGE_INDEX);
	}, [activeSlide]);

	const BulletText = styled(Typography)({
		textAlign: "left",
		font: "normal normal bold 20px/25px Helvetica Neue",
		letterSpacing: "0px",
		color: colors.darkBorder,
		opacity: 1,
	});

	const TitleText = styled(Typography)(({ theme }) => ({
		textAlign: "left",
		font: "normal normal normal 80px Univers",
		[theme.breakpoints.down("sm")]: {
			font: "normal normal normal 50px Univers",
		},
		letterSpacing: "-4px",
		color: colors.darkBorder,
		opacity: 1,
	}));

	return (
		<Box {...props}>
			<TitleText sx={{ marginTop: isMobile ? "100px" : "0px" }}>
				SkinScanner
				<R style={{ fontSize: isMobile ? "32px" : "40px" }} />{" "}
				Advantages
			</TitleText>
			<Grid container direction={"row-reverse"}>
				<Grid
					item
					xs={12}
					lg={5}
					sx={{
						position: "relative",
						display: "flex",
						justifyContent: "center",
						minHeight: "400px",
					}}
					ref={ref}
				>
					{(isMobile ? inView : showModel) && <Scanner3D />}
				</Grid>
				<Grid
					item
					container
					xs={12}
					lg={7}
					sx={{
						justifyContent: "center",
						alignContent: "flex-start",
					}}
				>
					<Grid item xs={12}>
						<Typography
							sx={{
								textAlign: "left",
								font: "normal normal 350 16px/25px Helvetica Neue",
								letterSpacing: "0px",
								color: colors.darkBorder,
								opacity: 1,
								marginTop: "44px",
								paddingRight: "44px",
							}}
						>
							Dermatologist-grade analysis always requires a
							device, because only a device can provide{" "}
							<b>magnification</b>, <b>moisture sensing</b>, and
							special <b>lighting</b> to allow measurement above
							and below the skin surface. As a result, app-only
							solutions are just gimmicks, not dermatologist-grade
							devices!
						</Typography>
						<BulletText sx={{ marginTop: "32px" }}>
							• Validated lab-grade results with a smartphone
						</BulletText>
						<BulletText sx={{ marginTop: "16px" }}>
							• One device for skin, color, and hair analysis
						</BulletText>
						<BulletText sx={{ marginTop: "16px" }}>
							• International patents granted & pending
						</BulletText>
					</Grid>
				</Grid>
			</Grid>
		</Box>
	);
}
