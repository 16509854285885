import * as React from "react";
import "./App.css";
import Navigation from "./Navigation.js";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { FitskinTheme } from "styles/FitskinTheme";

function App() {
	return (
		<>
			<CssBaseline />
			<ThemeProvider theme={FitskinTheme}>
				<Navigation />
			</ThemeProvider>
		</>
	);
}

export default App;
