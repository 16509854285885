import React from "react";
import _ from "lodash";
import { Typography, Box, Button, Divider, Link } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { colors } from "styles/StyleGuide";
import FullBox from "components/FullBox";
import useBreakpoints from "hooks/useBreakpoints";
import { Breakpoints, MENU_HEIGHT } from "Constants";

import { partnerList } from "data/partners";

export default function Clients({ page, ...props }) {
	const [breakpoint, isMobile] = useBreakpoints();

	const partners = !page
		? partnerList
		: page === 1
		? partnerList.slice(0, 4)
		: partnerList.slice(4);

	const getColumnCount = () => {
		switch (breakpoint) {
			case Breakpoints.XS:
			case Breakpoints.SM:
				return 1;
			case Breakpoints.MD:
				return 2;
			case Breakpoints.LG:
			case Breakpoints.XL:
				return 2;
			default:
				return 2;
		}
	};

	const PartnerItem = ({ item }) => {
		return (
			<Box
				sx={{
					background: `${colors.brown} 0% 0% no-repeat padding-box`,
					borderRadius: "20px",
					overflow: "hidden",
					paddingY: "min(5%, 32px)",
					paddingX: "min(5%, 32px)",
					display: "flex",
					flexDirection: "column",
					alignItems: "center",
					width: `calc(${
						isMobile ? "100%" : "80%"
					} / ${getColumnCount()} - 2.5%)`,
					//width: "47.5%",
					height: isMobile ? "350px" : "48%",
					position: "relative",
				}}
			>
				<Link
					component={!!item.url ? Link : "div"}
					href={item.url}
					target="_blank"
					rel="noopener"
					underline={"none"}
					style={{ cursor: "pointer" }}
				>
					<Typography
						sx={{
							paddingBottom: "12px",
							textAlign: "center",
							font: "normal normal bold 25px/30px Univers",
							letterSpacing: "-1.25px",
							color: colors.white_shade_1,
						}}
					>
						{item.brand}
					</Typography>
				</Link>
				<Typography
					sx={{
						textAlign: "center",
						font: "normal normal normal 16px/18px Univers",
						letterSpacing: "0.16px",
						color: colors.white_shade_1,
					}}
				>
					{item.text}
				</Typography>
				<Box
					sx={{
						flex: "1 1 0%",
						marginTop: "16px",
						overflow: "hidden",
					}}
				>
					{!!item.image && (
						<img
							src={item.image}
							alt={"poster"}
							style={{
								display: "block",
								margin: "auto",
								maxHeight: "100%",
								maxWidth: "100%",
							}}
						/>
					)}

					{!!item.video && (
						<video
							controls
							style={{
								display: "block",
								margin: "auto",
								maxHeight: "100%",
								maxWidth: "100%",
							}}
							src={item.video}
							poster={item.videoImage ?? ""}
						>
							Your browser does not support the video tag.
						</video>
					)}
				</Box>
			</Box>
		);
	};

	return (
		<Box {...props}>
			<Typography
				sx={{
					textAlign: "center",
					font: "normal normal normal 65px/70px Univers",
					fontSize: { xs: 50, sm: 65 },
					letterSpacing: "0px",
					color: colors.darkBorder,
					textTransform: "uppercase",
					opacity: 1,
					marginBottom: "32px",
				}}
			>
				CLIENTS
			</Typography>
			<Box
				sx={{
					rowGap: isMobile ? "16px" : "2.5%",
					columnGap: "2.5%",
					display: "flex",
					flexWrap: "wrap",
					height: `calc(100% - ${MENU_HEIGHT}px)`,

					alignContent: "flex-start",
					alignItems: "stretch",
					//justifyContent: "space-between",
					justifyContent: "center",
					boxSizing: "border-box",
				}}
			>
				{_.map(partners, (item, index) => (
					<PartnerItem key={index} item={item} />
				))}
			</Box>
		</Box>
	);
}
