import React from "react";

import ScrollLayout from "../components/ScrollLayout";
import FullBox from "components/FullBox";
import { Typography, Box, CircularProgress } from "@mui/material";
import { colors } from "styles/StyleGuide";
import LoadingText from "components/LoadingText";

import imgBg from "img/Background - Contact.jpg";

export default function LoadingPage({ text }) {
	return (
		<ScrollLayout bgImage={imgBg}>
			<FullBox
				innerSx={{
					justifyContent: "center",
					alignItems: "center",
					alignContent: "center",
					//width: "30%",
				}}
			>
				<LoadingText />
			</FullBox>
		</ScrollLayout>
	);
}
