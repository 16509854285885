import React from "react";

import { Typography, Box, CircularProgress } from "@mui/material";
import { colors } from "styles/StyleGuide";

import imgBg from "img/Background - Contact.jpg";

export default function LoadingText({ text, color }) {
	return (
		<Box
			sx={{
				display: "flex",
				flexDirection: "column",
				//width: "30%",
				justifyContent: "center",
				alignItems: "center",
				alignContent: "center",
			}}
		>
			<Typography
				sx={{
					fontFamily: "Univers",
					fontSize: "65px",
					fontWeight: 65,
					letterSpacing: "-3.9px",
					color: !!color ? color : colors.white,
				}}
			>
				{!!text ? text : "Loading..."}
			</Typography>
			<CircularProgress
				disableShrink
				style={{ alignSelf: "center" }}
				sx={{ color: !!color ? color : colors.white }}
			/>
		</Box>
	);
}
