import React, { useState, useCallback, useEffect, useRef } from "react";
import _ from "lodash";
import { Typography, Box, Button, Divider, Grid } from "@mui/material";
import { styled } from "@mui/system";
import { colors } from "styles/StyleGuide";
import useBreakpoints from "hooks/useBreakpoints";
import { useSpring, animated, config, Spring } from "@react-spring/web";

const titleParts = ["EVERYONE", "IS", "BEAUTIFUL"];

export default function WelcomeAnimation({ ...props }) {
	const [breakpoint, isMobile] = useBreakpoints();

	/* HOME PAGE ANIMATION */
	const [isHide, setHide] = useState(true);
	const DurationTrail = (props) => {
		const {
			children,
			delay = 500,
			ms = 1000,
			keys,
			onRest,
			reverse,
			...otherProps
		} = props;

		return children.map((child, i) => {
			const waitTime = (reverse ? children.length - 1 - i : i) * ms;
			return (
				<Spring
					key={keys[i]}
					{...otherProps}
					reverse={reverse}
					delay={delay + waitTime}
					onRest={
						i === (reverse ? 0 : children.length - 1)
							? onRest
							: null
					}
					children={child}
				/>
			);
		});
	};

	const TitleText = styled(Typography)(({ theme }) => ({
		textAlign: "center",
		font: "normal normal bold 50px/72px Univers",

		[theme.breakpoints.up("sm")]: {
			font: "normal normal normal 95px/113px Univers",
		},

		letterSpacing: "0.95px",
		color: colors.darkBorder,
		textTransform: "uppercase",
		opacity: 1,
		marginLeft: "32px",
		alignSelf: "center",
		//backgroundColor: "blue",
	}));
	const TitleAnimated = animated(TitleText);

	const AnimGrid = animated(Grid);

	// const [animationRect, setAnimationRect] = useState(null);
	// const animationCallback = useCallback((node) => {
	// 	if (node != null) {
	// 		const bounds = node.getBoundingClientRect();
	//
	// 		setAnimationRect(bounds);
	// 	}
	// }, []);

	const ScannerGridItem = ({ scanner, index }) => {
		const ref = useRef(null);

		const [offset, setOffset] = useState({ x: 0, y: 0 });

		const animationProps = useSpring({
			config: { ...config.molases, friction: 105, delay: 500 },

			from: { x: 0, y: 0 },
			to: { x: offset.x, y: offset.y },
			reverse: !isHide,
		});

		const OffsetRef = useCallback((node) => {
			if (node != null) {
				const animRect = node.parentNode.getBoundingClientRect();
				const rect = node.getBoundingClientRect();

				const centerRect = {
					x: animRect.x + animRect.width * 0.5,
					y: animRect.y + animRect.height * 0.5,
				};

				const off = {
					x: centerRect.x - rect.x - 0.5 * rect.width,
					y: centerRect.y - rect.y - 0.5 * rect.height,
				};

				// console.log(
				// 	"rendering off",
				// 	off,
				// 	rect.height,
				// 	animRect.y,
				// 	animRect.height
				// );
				//debugger;

				setOffset(off);
			}
		}, []);

		return (
			<Grid
				ref={OffsetRef}
				item
				key={index}
				xs={2}
				md={1}
				//sx={{ backgroundColor: "green" }}
			>
				<animated.img
					alt="scanner"
					src={scanner}
					style={{
						//backgroundcolor: "yellow",
						width: "100%",
						...animationProps,
					}}
				/>
			</Grid>
		);
	};

	return (
		<Box {...props}>
			<Grid container columns={12}>
				<Grid item xs={0} sm={1} />
				<Grid item xs={12} sm={10}>
					<Grid
						// ref={animationCallback}
						container
						columns={8}
						spacing={"10px"}
						//sx={{ backgroundColor: "cyan" }}
					>
						{_.map(props.scanners, (scanner, index) => (
							<ScannerGridItem
								scanner={scanner}
								index={index}
								key={index}
							/>
						))}
					</Grid>
				</Grid>
				<Grid item xs={0} sm={1} />
			</Grid>

			<Box
				sx={{
					display: "flex",
					flexWrap: "wrap",
					alignItems: "center",
					justifyContent: "center",
					marginTop: "62px",

					//backgroundColor: "red",
				}}
			>
				{/* <TitleText>EVERYONE IS BEAUTIFUL</TitleText> */}
				<DurationTrail
					//native
					keys={titleParts}
					from={{ opacity: 0 }}
					to={{ opacity: 1 }}
					reverse={!isHide}
					//	loop
					onRest={() => setHide(!isHide)}
				>
					{titleParts.map((item) => (style) => (
						<TitleAnimated key={item.key} style={style}>
							{item}
						</TitleAnimated>
					))}
				</DurationTrail>
			</Box>
		</Box>
	);
}
