import React, { useRef, useState, Suspense, lazy, useEffect } from "react";
import { Tooltip } from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import LoadingText from "components/LoadingText";
import { colors } from "styles/StyleGuide";

const ScannerModel = lazy(() => import("components/ScannerModel"));

export default function Scanner3D() {
	return (
		<Suspense fallback={<LoadingText color={colors.darkBorder} />}>
			<ScannerModel />
			<Tooltip
				sx={{
					position: "absolute",
					bottom: 4,
					right: 4,
					zIndex: 1,
					whiteSpace: "pre-wrap",
				}}
				title={`(1) Click and drag to rotate the scanner or (2) click on orange dots to see details about the scanner`}
			>
				<InfoOutlinedIcon style={{ color: colors.darkBorder }} />
			</Tooltip>
		</Suspense>
	);
}
