import React from "react";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { Breakpoints, H_Breakpoints as H_BP } from "Constants";

export default function useBreakpoints() {
	const theme = useTheme();

	// Width Breakpoints
	const isXS = useMediaQuery(theme.breakpoints.only(Breakpoints.XS));
	const isSM = useMediaQuery(theme.breakpoints.only(Breakpoints.SM));
	const isMD = useMediaQuery(theme.breakpoints.only(Breakpoints.MD));
	const isLG = useMediaQuery(theme.breakpoints.only(Breakpoints.LG));
	const isXL = useMediaQuery(theme.breakpoints.only(Breakpoints.XL));
	const isSmallScreen = useMediaQuery(theme.breakpoints.down(Breakpoints.LG));

	// Height Breakpoints
	const makeQuery = (min, max) =>
		`@media (min-height:${min}) ${max ? `and (max-height:${max})` : ""}`;

	const H_isXSmall = useMediaQuery(makeQuery(0, H_BP.XS));
	const H_isSmall = useMediaQuery(makeQuery(H_BP.XS, H_BP.SM));
	const H_isMedium = useMediaQuery(makeQuery(H_BP.SM, H_BP.MD));
	const H_isLarge = useMediaQuery(makeQuery(H_BP.MD, H_BP.LG));
	const H_isXLarge = useMediaQuery(makeQuery(H_BP.LG));

	const getCurrentBreakpoint = () => {
		return isXS
			? Breakpoints.XS
			: isSM
			? Breakpoints.SM
			: isMD
			? Breakpoints.MD
			: isLG
			? Breakpoints.LG
			: Breakpoints.XL;
	};

	const getHeightBreakpoint = () => {
		return H_isXSmall
			? H_BP.XS
			: H_isSmall
			? H_BP.SM
			: H_isMedium
			? H_BP.MD
			: H_isLarge
			? H_BP.LG
			: H_BP.XL;
	};

	return [getCurrentBreakpoint(), isSmallScreen, getHeightBreakpoint()];
}
