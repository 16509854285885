import React, { useState } from "react";
import _ from "lodash";
import { Link as RouterLink } from "react-router-dom";
import { Box, Typography, Link } from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Drawer from "@mui/material/Drawer";
import MenuIcon from "@mui/icons-material/Menu";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { MENU_HEIGHT, Pages } from "Constants";
import { colors } from "styles/StyleGuide";
import useBreakpoints from "hooks/useBreakpoints";

export default function MenuBar(props) {
	const [breakpoints, isSmallScreen] = useBreakpoints();

	// For mobile menu
	const [isOpen, setIsOpen] = useState(false);

	const menuItems = [
		{ name: "HOME", bgColor: "#DFACA9", link: Pages.Home },
		{ name: "AWARDS & PRESS", bgColor: "#DCB88E", link: Pages.Awards },
		{ name: "TEAM", bgColor: "#B39378", link: Pages.Team },
		{ name: "NEWS", bgColor: "#976F52", link: Pages.News },
		{ name: "CONTACT", bgColor: "#684938", link: Pages.Contact },
	];

	const MenuItem = ({ item }) => {
		return (
			<Link
				underline="none"
				component={RouterLink}
				to={item.link || Pages.Home}
				onClick={() => {
					if (window.location.pathname === item.link) {
						window.location.reload();
					}
					setIsOpen(false);
				}}
				sx={{
					display: "flex",
					justifyContent: "center",
					alignItems: "center",
					bgcolor: item.bgColor,
					height: MENU_HEIGHT,
				}}
			>
				<Typography
					sx={{
						textAlign: "left",
						font: "normal normal bold 20px/24px Univers",
						letterSpacing: "1px",
						color: colors.white,
						opacity: 1,
					}}
					style={{ alignSelf: "center" }}
				>
					{item.name}
				</Typography>
			</Link>
		);
	};

	const MobileMenu = () => {
		const DrawerHeader = () => {
			return (
				<AppBar position="static">
					<Toolbar>
						<IconButton
							size="large"
							edge="start"
							color="inherit"
							aria-label="menu"
							sx={{ mr: 2 }}
							onClick={() => setIsOpen(!isOpen)}
						>
							<CloseRoundedIcon sx={{ color: colors.white }} />
						</IconButton>
					</Toolbar>
				</AppBar>
			);
		};

		const MenuButton = () => (
			<IconButton
				size="large"
				edge="start"
				sx={{
					filter: "drop-shadow(1px 1px 1px rgb(0 0 0 / 0.4))",
					marginLeft: 2,
					marginTop: 1,
					color: props.useBlackImage
						? colors.darkBorder
						: colors.white,
				}}
				onClick={() => setIsOpen(true)}
			>
				<MenuIcon />
			</IconButton>
		);
		return (
			<Box
				sx={{
					position: "fixed",
					top: 0,
					left: 0,
					width: "20rem",
					zIndex: 1,
				}}
			>
				<MenuButton />

				<Drawer
					PaperProps={{
						sx: {
							width: "20rem",
							height: MENU_HEIGHT * (menuItems.length + 1),
						},
					}}
					anchor={"left"}
					open={isOpen}
					onClose={() => setIsOpen(false)}
				>
					<DrawerHeader />
					{_.map(menuItems, (item, index) => (
						<MenuItem key={index} item={item} />
					))}
				</Drawer>
			</Box>
		);
	};

	const DesktopMenu = () => {
		return (
			<Box
				sx={{
					display: "grid",
					gridTemplateColumns: "repeat(5, 1fr)",
					width: "100vw",
					height: MENU_HEIGHT,
					position: "fixed",
					bottom: 0,
					zIndex: 2,
				}}
			>
				{_.map(menuItems, (item, index) => (
					<MenuItem key={index} item={item} />
				))}
			</Box>
		);
	};

	return isSmallScreen ? <MobileMenu /> : <DesktopMenu />;
}
