import imgAllergan from "img/clients/allergan_logo_400x400.png";
import imgFG from "img/clients/FG_Stills_90_v1_Chin_small.jpg";
import imgJPMS from "img/clients/JPMS_Still_Watermark.jpg";
import imgSkincredible from "img/clients/skincredible.png";
import imgSuperpharm from "img/clients/Super-Pharm-poster.jpeg";

import skin360Still from "img/skin360-video-still.png";

import imgNo7 from "img/clients/no7-app.jpg";

export const partnerList = [
	{
		brand: "Sephora Skincredible",
		text: "Powered By FitSkin®",
		image: imgSkincredible,
	},
	{
		brand: "Wallgreens Boots Alliance",
		text: "Powered By FitSkin®",
		url: "https://www.no7company.com/news/2022/no7-introduces-new-skin-service-using-dermatologist-grade-device-high-street",
		image: imgNo7,
	},
	{
		brand: "FaceGenius™",
		text: "Powered By FitSkin®",
		url: "https://facegenius.com/",
		image: imgFG,
	},
	{
		brand: "Allergan",
		text: "FitSkin technology reinventing clinical trials.",
		image: imgAllergan,
	},
	{
		brand: "Super Pharm",
		text: "Powered By FitSkin®",
		image: imgSuperpharm,
	},
	{
		brand: "HairAI™",
		text: "Powered By FitSkin®",
		url: "https://youtu.be/8jBkaMw0TgI",
		image: imgJPMS,
	},
	{
		brand: "Neutrogena Skin360™",
		text: "Powered By FitSkin®",
		url: "https://skin360app.com/",
		video: `${process.env.PUBLIC_URL}/videos/8250251_Neutrogena_Skin360_SkinScanner_Video_0104.mov`,
		videoImage: skin360Still,
	},
];
